/*
 * @Author: jiang
 * @Date: 2021-07-14 16:25:27
 * @Description: 项目监管
 */
import request from '../axios'

/**
 * 获取项目统计信息
 * @param { number } year  年份查询
 */
export function getProjectStatistics(year) {
  return request({
    url: '/api/project/monitoring/count',
    params: {
      year,
    },
  })
}

/**
 * 获取项目行业
 */
export function getProjectIndustryList() {
  return request({
    url: '/api/project/monitoring/industry/list',
  })
}

/**
 * 获取项目建设单位
 */
export function getProjectDepartmentList() {
  return request({
    url: '/api/project/monitoring/department/list',
  })
}

/**
 * 分页获取项目
 * @param { object } params
 * @param { string } params.year - 年份查询
 * @param { string } params.progress_state - 预警状态 0：正常；1橙色预警；2黄色预警
 * @param { string } params.build_state - 建设状态 1：在建，2：待验收
 * @param { string } params.industry - 行业
 * @param { string } params.department - 建设单位
 */
export function getProjectPage(params) {
  return request({
    url: '/api/project/monitoring/list',
    params,
  })
}

/**
 * 获取项目详情
 * @param { string } id
 */
export function getProject(id) {
  return request({
    url: `/api/project/monitoring/list/${id}`,
  })
}
